import React from "react";
import capa from "./assets/capa_livro.png";

function Checkout() {
  const handleBackToHome = () => {
    window.location.href = "/"; // Altere o caminho conforme necessário
  };

  const handleMBWayPayment = () => {
    alert("O livro custa 16€ + portes de envio. Você será redirecionado para uma conversa via WhatsApp para mais detalhes.");
    window.location.href = "https://wa.me/351924023684?text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20a%20compra%20do%20livro.";
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 bg-opacity-80">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">Finalizar Compra</h1>
        
        {/* Informações do Livro */}
        <div className="flex justify-center mb-6">
          <img
            src={capa}
            alt="Capa do livro Retalhos de mim..."
            className="rounded-lg shadow-lg max-w-xs"
          />
        </div>
        
        {/* Botão de Pagamento MB Way */}
        <div className="flex justify-center mt-6">
          <button 
            onClick={handleMBWayPayment}
            className="px-4 py-2 bg-green-500 text-white font-bold rounded-lg shadow hover:bg-green-600"
          >
            Informações de Pagamento
          </button>
        </div>

        <p id="result-message" className="mt-4 text-center text-gray-700"></p>

        {/* Botão para voltar à página principal */}
        <div className="flex justify-center mt-6">
          <button 
            onClick={handleBackToHome}
            className="px-4 py-2 bg-blue-500 text-white font-bold rounded-lg shadow hover:bg-blue-600"
          >
            Voltar à Página Principal
          </button>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
