import React from "react";
import { useNavigate } from "react-router-dom";
import capa from "./assets/capa_livro.png";
import fundo from "./assets/fundo_ilha.jpg";

function Page() {
    const navigate = useNavigate();

    const handleCheckout = () => {
        navigate("/checkout");
    };

    return (
        <div className="bg-cover bg-center min-h-screen" style={{ backgroundImage: `url(${fundo})` }}>
            <div className="container mx-auto p-4">
                <div className="bg-white rounded-lg shadow-lg p-8 bg-opacity-80">
                    <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">Lançamento do Livro</h1>
                    <h2 className="text-2xl font-semibold text-center text-gray-700 mb-2">Retalhos de uma Vida...</h2>
                    <p className="text-center text-gray-600 mb-4">por Maria de Fátima Mascarenhas</p>
                    <p className="text-center text-gray-600 mb-6">Publicado pela Editora SANA</p>
                    
                    <div className="flex justify-center mb-6">
                        <img
                            src={capa}
                            alt="Capa do livro Retalhos de uma Vida..."
                            className="rounded-lg shadow-lg max-w-xs"
                        />
                    </div>

                    <div className="mt-6 text-center">
                        <p className="text-gray-700 mb-4">
                            Maria de Fátima Mascarenhas nasceu em Búzi, no ano de 1954. O seu percurso foi marcado por diversos caminhos que a levaram à Suíça, onde terminou a sua vida profissional como stewardess na SSG em 2018. Diagnosticada logo de seguida com um cancro, superou-o em pouco mais de um ano.
                        </p>
                        <p className="text-gray-700 mb-4">
                            Nunca conseguiu esquecer os seus primeiros anos de vida como filha de pai falsamente incógnito. Desejou tanto ser mãe que um milagre aconteceu: hoje, ele é um homem adulto. Depois de viver anos agarrada aos traumas do passado, superou-os e é modelo de duas agências, divertindo-se no TIKTOK. Quase septuagenária, vive a vida com uma alegria contagiante.
                        </p>
                    </div>

                    <div className="mt-6 text-center">
                        <p className="text-gray-700 mb-4">
                            A fé de Maria de Fátima ajudou-a, desde muito pequena, a superar todas as adversidades: não conhece a verdadeira história por trás do seu nascimento, foi criada de uma forma muito dura, mas, mesmo assim, nunca perdeu a inocência infantil que preserva até aos dias de hoje. Saiu fugida de Moçambique, passou por Portugal, seguiu para a Suíça e com ela levou os traumas do passado.
                        </p>
                        <p className="text-gray-700 mb-4">
                            Um percurso cheio de peripécias onde nunca faltou a fé e a perseverança. Encontrou uma família que a acolheu, um amor para a vida, foi mãe e teve tudo o que sempre quis, não sem antes ultrapassar os traumas com a ajuda de terapia. Depois de se reformar, ainda teve de vencer um cancro. Hoje é motivada, feliz, ativa e assídua nas redes sociais onde faz furor. Quer partilhar alguns dos seus "Retalhos de uma vida" porque considera que pode ajudar outras pessoas.
                        </p>
                    </div>
                    
                    <div className="mt-8 text-center">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700" onClick={handleCheckout}>
                            Compre Agora
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page;
